import { createGlobalStyle } from 'styled-components';

export const BrandSitesV2GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Pretendard, Apple SD Gothic Neo, Apple Sandoll Gothic Neo, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, 'Helvetica Neue', 'Segoe UI', 'Noto Sans KR', 'Malgun Gothic', sans-serif !important;
    /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
  }

  html {
    font-size: 16px;
    text-size-adjust: none;
  }

  body {
    background: #ffffff;
    color: rgba(0, 0, 0, 0.85);
  }

  img {
    user-select: none;
    -webkit-touch-callout: none;
    vertical-align: bottom;
    pointer-events: none;
  }

  a {
    text-decoration: inherit;
    color: inherit;

    &:hover, &:focus, &:active {
      text-decoration: inherit;
    }
  }

  .bg-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;

    background-color: var(--base-color);
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );

    background-size: 200px 100%;
    background-repeat: no-repeat;

    animation-name: bg-skeleton;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

export const BrandSitesV2Theme = {
  brandSitesV2: {
    breakpoints: {
      // tablet: '1280px',
      // mobile: '834px',
      tablet: '1279px',
      mobile: '833px',
    },
    containerWidths: {
      desktop: '1024px',
      tablet: '712px',
      mobile: '327px',
    },
    headerZIndex: 9,
  },
};

declare module 'styled-components' {
  export interface DefaultTheme {
    brandSitesV2?: typeof BrandSitesV2Theme.brandSitesV2;
  }
}
